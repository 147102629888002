import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class SignInService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) {}

  login(email: string, password: string) {
    const credentials = {
      email: email,
      password: password,
    };

    return this.http.post<any>(`${this.baseUrl}/auth/login`, credentials);
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/sign-in']);
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }
}
